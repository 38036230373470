import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template"];

  connect() {
  }

  appendEntry(e) {
    let templateHTML = this.templateTarget.innerHTML;
    const uniqueId = Date.now();
    templateHTML = templateHTML.replace(/namespace/g, uniqueId);
    this.templateTarget.insertAdjacentHTML('beforebegin', templateHTML);
  }

  disconnect() {}
}
