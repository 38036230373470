import { Controller } from "stimulus";
import Chocolat from "chocolat";

export default class extends Controller {
  static targets = [];
  static values = { images: Array }

  connect() {
    let images = this.imagesValue;

    const { api } = Chocolat(images, {
      allowZoom: false,
      fullScreen: false
    });
    this.chocolate = api;

  }

  open() {
    this.chocolate.open();
  }

  disconnect() {
    this.chocolate = null;
  }
}
