import { Controller } from "stimulus"
import Rellax from 'rellax';

export default class extends Controller {
  static targets = [];

  connect() {
    this.rellax = new Rellax(".parallax", {
      speed: -3,
      breakpoints: [700, 900, 1024]
    });
  }

  disconnect() {
    if(this.rellax?.options)
      this.rellax.destroy();
  }
}