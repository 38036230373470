import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];
  static values = {};

  connect() {

  }

  updateLocale() {
    this.checkboxTargets.forEach(locale => {
      if(locale.checked)
        this.element.classList.add(`locale--${locale.value}`);
      else if(this.checkedLanguagesCount() >= 1)
        this.element.classList.remove(`locale--${locale.value}`);
    });
  }

  checkedLanguagesCount() {
    let count = 0;
    this.checkboxTargets.forEach(locale => {
      if(locale.checked) count ++;
    });
    return count;
  }

  disconnect() {

  }
}
