import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["backdrop","mobileMenu"];

  toggle( overlay ) {
    overlay.contains('open') ? this.closeBackdrop() : this.openBackdrop();
    overlay.toggle('open');
  }

  /**
   * Either open or close the Mobile Menu depending on its current state
   * @param e The click event
   */
  toggleMobileMenu(e) {
    this.toggle( this.mobileMenuTarget.classList );
    this.element.classList.toggle("noscroll");
  }

  /**
   * Display Backdrop
   */
  openBackdrop() {
    this.backdropTarget.classList.add('open');
  }

  /**
   * Hide Backdrop
   */
  closeBackdrop() {
    this.backdropTarget.classList.remove('open');
  }

  /**
   * Hide all overlays and the backdrop
   */
  close() {
    this.backdropTarget.classList.remove('open');
    if(this.hasMobileMenuTarget)
      this.mobileMenuTarget.classList.remove('open');
    this.element.classList.remove("noscroll");
  }
}
