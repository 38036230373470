import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];
  static values = {};

  connect() {

  }

  updateType(e) {
    ["highlight", "standard"].forEach(type => {
      this.element.classList.remove(`type--${type}`);
    });
    this.element.classList.add(`type--${e.target.value}`);
  }

  disconnect() {

  }
}
