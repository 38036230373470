import { Controller } from "stimulus"
import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["positionInput"]
  sortable;

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: ".sortable",
      handle: ".sortable__handle",
      animation: 170,
    });
  }

  /**
   * Update all hidden position input fields with their new position
   */
  updatePositionFields(e) {
    // Iterating through input elements in DOM to get them in the correct order
    const positionInputs = this.element.querySelectorAll('input[data-admin--sort-target="positionInput"]');
    for( let i=0; i<positionInputs.length; i++) {
      positionInputs[i].value = i+1;
    }
  }

  /**
   * Submit the items position
   */
   submitPosition(e) {
    const position = e.newIndex + 1;

    Rails.ajax({
      url: e.item.dataset.sortUrl,
      type: "patch",
      data: `position=${position}`,
      success: (data) => {
        const ajaxEvent = new CustomEvent("ajax:success", { detail: [data, "OK"], bubbles: true});
        e.item.dispatchEvent(ajaxEvent);
      }
    });
  }

  disconnect() {
    this.sortable.destroy();
  }
}



