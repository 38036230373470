import Swiper, { Navigation, Mousewheel, Keyboard, Pagination, HashNavigation } from "swiper";
Swiper.use([Navigation, Mousewheel, Keyboard, Pagination, HashNavigation]);
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "prevButton", "nextButton", "link", "title"];

  connect() {
    this.sliderContainer = this.hasContainerTarget ? this.containerTarget : this.element;
    this.swiper = new Swiper(this.sliderContainer, {
      spaceBetween: 0,
      simulateTouch: true,
      width: null,
      navigation: {
        nextEl: this.nextButtonTargets,
        prevEl: this.prevButtonTargets,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "custom",
        renderCustom:  (swiper, current, total) => {
          return ("0" + current).slice(-2) + "/" + ("0" + total).slice(-2);
        }
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        forceToAxis: true,
        thresholdDelta: 30,
        thresholdTime: 300
      },
      hashNavigation: true,
      grabCursor: true,
      // effect: "creative",
      // creativeEffect: {
      //   prev: {
      //     shadow: true,
      //     translate: ["-20%", 0, -1],
      //   },
      //   next: {
      //     translate: ["100%", 0, 0],
      //   },
      // },
    });
    // Propagate slide changes
    this.swiper.on("slideChange", this.onChange.bind(this));
    this.onChange();
  }

  onChange() {
    const slide = this.swiper.slides[this.swiper.activeIndex];
    const project = slide?.dataset;

    if(project) {
      this.titleTarget.innerHTML = project.projectTitle;
      this.linkTarget.href = project.projectUrl;
    }
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
