import { Controller } from "stimulus";
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static targets = [];
  static values = {}

  connect() {
    smoothscroll.polyfill();
  }

  toAnchor(e) {
    const link = e.target;

    if(link.host != window.location.host || link.pathname != window.location.pathname)
      return; // navigate via turbolinks

    e.preventDefault();
    const element = document.querySelector(link.hash);
    if(element) {
      const scrollMarginTop = parseInt(getComputedStyle(element).scrollMarginTop);
      const position = element.getBoundingClientRect().top - scrollMarginTop;
      window.scrollBy({ top: position, left: 0, behavior: 'smooth' });
      window.history.pushState({}, "", link);
    }
  }

  disconnect() {

  }
}
